import * as THREE from 'three';
import OrbitController from "./helpers/OrbitController";
import Stats from "stats-js";
import SETTINGS from "../Settings";
import { TweenLite, Power2, Power1 } from "gsap/TweenLite";

import World from './World';
import Player from './Player';
import ItemGenerator from './items/ItemGenerator';
import Interface from './interface';
import Preloader from './helpers/Preloader';
import CameraStory from './helpers/CameraStory';

import Timer from './helpers/Timer';
import Party from './helpers/Party';

import raf from 'raf';

import {
  EffectComposer,
  BlendFunction,
  NoiseEffect,
  EffectPass,
  RenderPass,
  TextureEffect
} from "postprocessing";

import STATE from '../State';

class Runner {
  constructor(app) {

    this.app = app;

    this.container = document.getElementById('game-container');

    this.delta = 0;

    this.state = {
      PARTY: new Party(this),
      TIMER: new Timer(this),
      PLAYING: false,
      RUNNING: false,
      PAUSED: false,
      ORIGINAL_SPEED: SETTINGS.START_SPEED * SETTINGS.STOP_MOTION,
      SPEED: SETTINGS.START_SPEED * SETTINGS.STOP_MOTION,
      GAME_SPEED: SETTINGS.START_SPEED * SETTINGS.STOP_MOTION,
      MENU_SPEED: 0,
      INTRO_SPEED: SETTINGS.START_SPEED / 2,
      ACCELERATION: SETTINGS.ACCELERATION,
      MAX_SPEED: SETTINGS.MAX_SPEED, //0.1,
      FIRST_PARTY: true
    };

    this.renderOptions = {
      // performance: "high-performance",
      alpha: false,
      antialias: false,
      powerPreference: "low-power"
    };

    this.renderer = new THREE.WebGLRenderer(this.renderOptions);

    this.resize(true);

    this.renderer.setSize(this.width, this.height);

    this.container.appendChild(this.renderer.domElement);

    this.renderer.setClearColor(0xffbfa0, 1);

    window.game = this;

    window.addEventListener('resize', this.resize.bind(this), false);

    this.addDebounceResize();
  }
  init() {
    this.preload = new Preloader({
      game: this,
      onEnd: this.setup.bind(this)
    });
  }
  setup(textures, files) {
    this.TEXTURES = textures;
    this.FILES = files;

    this.scene = new THREE.Scene();

    if (SETTINGS.SHOW_FPS) {
      this.stats = new Stats();
      document.body.appendChild(this.stats.dom);
    }

    this.addCamera();

    this.clock = new THREE.Clock();

    if (SETTINGS.ORBIT) {
      this.orbit = new OrbitController(this.camera, this.renderer);
    }

    this.world = new World(this);
    this.player = new Player(this);
    this.itemGenerator = new ItemGenerator(this);

    this.interface = new Interface(this);

    this.resize();

    this.update();

    //SAFE INSTAGRAM WEBVIEW IOS
    setTimeout(()=>{
      this.resize();
    }, 750);

    this.itemGenerator.reset();

    this.cameraStory.placeCameraMenu();

    if (SETTINGS.SKIP_INTRO) {
      this.introduction();
      this.start();
    }
  }
  addCamera() {

    if (SETTINGS.LARGE_FOV) {
      this.camera = new THREE.PerspectiveCamera(100, (this.width / this.height), 0.1, 40);
      this.camera.position.set(0, 2, 3);
    } else {
      this.camera = new THREE.PerspectiveCamera(70, (this.width / this.height), 0.1, 40);
      this.camera.position.set(0, 2.2, 2.5);
    }

    if (SETTINGS.PAPER_FILTER) {
      this.addTexturePass();
    }

    this.camera.lookAt(0,0,-10);

    this.cameraStory = new CameraStory(this, this.camera, this.app);

    if (SETTINGS.FOG) {
      this.scene.fog = new THREE.Fog(0xffbfa0, 5, 15);
    }

  }
  addTexturePass() {
    this.composer = new EffectComposer(this.renderer);

    const texture = this.TEXTURES.paper.clone();
    texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
    texture.needsUpdate = true;

    //DARKEN | MULTIPLY | OVERLAY
    const textureEffect = new TextureEffect({
      blendFunction: BlendFunction.MULTIPLY,
      texture: texture
    });

    // textureEffect.blendMode.opacity.value = 0.25;
    // textureEffect.blendMode.opacity.value = 0.75;
    textureEffect.blendMode.opacity.value = 0.1;

    // const noiseEffect = new NoiseEffect({
    //   blendFunction: BlendFunction.OVERLAY,
    // });

    // noiseEffect.blendMode.opacity.value = 0.2;


    const pass = new EffectPass(this.camera, textureEffect);
    pass.renderToScreen = true;


    this.effect = textureEffect;
    this.pass = pass;

    this.renderPass = new RenderPass(this.scene, this.camera);

    this.composer.addPass(this.renderPass);
    this.composer.addPass(this.pass);
  }
  addHelper() {
    const axesHelper = new THREE.AxesHelper(10);

    this.scene.add(axesHelper);
  }
  addTest() {
    this.geometry = new THREE.BoxGeometry(0.2, 0.2, 0.2);
    this.material = new THREE.MeshNormalMaterial();

    this.mesh = new THREE.Mesh(this.geometry, this.material);
    this.scene.add(this.mesh);
  }
  update() {
    if (SETTINGS.SHOW_FPS) {
      this.stats.begin();
    }

    if (this.state.RUNNING) {
      this.run();
    }

    if (SETTINGS.FPS === "auto") {
      this.render();
    } else if (this.delta % 2 === 0) {
      this.render();
    }

    raf(this.update.bind(this));

    // this.lastUpd = new Date().getTime();

    // if ((new Date().getTime()) - this.lastUpd > SETTINGS.REAL_FPS*2){
    //   this.update();
    // } else {
    //   setTimeout(this.update.bind(this), 1/SETTINGS.REAL_FPS * 1000);
    // }
  }
  render() {
    if (SETTINGS.PAPER_FILTER) {
      this.composer.render(this.clock.getDelta());
    } else {
      this.renderer.render(this.scene, this.camera);
    }

    if (SETTINGS.SHOW_FPS) {
      this.stats.end();
    }
  }
  run() {
    if ((!this.state.PLAYING && !this.state.RUNNING) || this.state.PAUSED) {
      return false;
    }

    this.delta += 1; //1

    if (!this.state.PLAYING && !this.state.RUNNING) {
      this.state.SPEED = this.state.MENU_SPEED;
    } else if (!this.state.PLAYING && this.state.RUNNING) {
      this.state.SPEED = this.state.INTRO_SPEED;
    } else if (this.state.SPEED < this.state.MAX_SPEED && this.state.PLAYING && this.state.RUNNING) {
      this.state.SPEED += this.state.ACCELERATION * SETTINGS.STOP_MOTION;
    }

    // if (SETTINGS.SYNC_FPS_SPEED) {
    //   this.state.GAME_SPEED = (60/this.state.TIMER.fps) * this.state.SPEED;
    // } else{
    this.state.GAME_SPEED = this.state.SPEED;
    // }

    if (this.player && this.state.RUNNING) {
      this.player.update();
    }

    if (this.world) {
      this.world.update();
    }

    if (!this.state.PLAYING) {
      return false;
    }

    if (this.itemGenerator) {
      this.itemGenerator.update();
    }

    if (this.state.TIMER && this.delta % 4 === 0) {
      this.state.TIMER.clock();
    }

    this.interface.update();

    // if (this.state.RUNNING) {
    //   this.lastUpd = new Date().getTime();

    //   if ((new Date().getTime()) - this.lastUpd > SETTINGS.REAL_FPS){
    //     this.run();
    //   } else {
    //     setTimeout(this.run.bind(this), 1/SETTINGS.REAL_FPS * 1000);
    //   }
    // }
  }

  dead() {
    this.interface.gameover();

    this.state.RUNNING = false;

    this.state.PLAYING = false;

    this.app.FBGame.saveScoreToLeaderBoard(this.state.PARTY.SCORE);

    this.app.screensManager.close('interface');

    this.cameraStory.outro();
    
    this.app.audio.stopCrowd();

    this.app.audio.playVoice('choc');

    this.app.audio.fadeOutCurrentTheme();

    setTimeout(()=>{
      this.app.audio.play('crowdOh');
    }, 500);

  }
  introduction() {
    if (this.itemGenerator) {
      // this.itemGenerator.empty();
      this.itemGenerator.reset();
    }

    this.cameraStory.intro({ replay: !this.state.FIRST_PARTY });

    this.app.audio.fadeOutCurrentTheme();

    this.state.RUNNING = true;
  }
  start() {

    if (!this.state.FIRST_PARTY) {
      return this.restart();
    }

    this.reset();

    this.app.screensManager.open('interface');

    this.state.FIRST_PARTY = false;

  }
  reset() {
    this.state.RUNNING = true;

    this.state.PLAYING = true;

    this.state.SPEED = this.state.ORIGINAL_SPEED;

    this.state.PARTY.reset();

    this.state.TIMER.start();

    this.interface.reset();

    this.app.audio.play('ingame', { loop: true, main: 'theme' });
  
    STATE.parties += 1;


    setTimeout(()=>{  
      this.app.audio.playCrowd();
    }, 1000);
  }
  restart() {
    this.reset();

    this.app.screensManager.open('interface');

    // this.cameraStory.intro({replay: true});

    this.player.reset();

  }
  pause() {
    this.state.PAUSED = true;
    this.state.TIMER.pause();
    this.app.screensManager.open('pause', false);
    this.app.audio.pauseAll();
  }
  resume() {
    this.state.PAUSED = false;
    this.state.TIMER.resume();
    this.app.audio.resumeAll();
    this.app.screensManager.close('pause', false);
  }
  addDebounceResize () {
    this.debounceTimeout = null;

    window.addEventListener('resize' , ()=>{
      clearTimeout(this.debounceTimeout);

      this.debounceTimeout = setTimeout(()=>{
        this.resize();
      }, 300);
    });
  }
  resize(first = false) {
    this.width = this.container.offsetWidth;
    this.height = this.container.offsetHeight;

    if (first === true) {
      return;
    }
    
    if (this.camera) {
      this.camera.aspect = this.width / this.height;
      this.camera.updateProjectionMatrix();
    }

    if (this.renderer) {

      this.renderer.setSize(this.width, this.height);

      if (SETTINGS.PAPER_FILTER) {
        this.composer.setSize(this.width, this.height);
      }

      if (!this.state.RUNNING) {
        this.render();
      }
    }

    if (!SETTINGS.FB_ACTIVE && SETTINGS.PLATFORM.INAPP.isMobile) {
      if (window.innerWidth > window.innerHeight && !this.app.screensManager.screens.score.opened) {
        this.app.screensManager.open('rotation', false);
      } else {
        this.app.screensManager.close('rotation');
      }
    }

  }
}

export default Runner;