import Challenge from './Challenge';
import Runner from './runner/Runner';
import ScreensManager from './screens/ScreensManager';
import AudioGame from './audio/AudioGame';
import FacebookGame from './facebook/';

import SETTINGS from './Settings';
import STATE from './State';
import GameAnalytics from './GameAnalytics';

export default class App {
  constructor () {

    if (SETTINGS.ARCADE_MODE) {
      document.body.classList.add('arcade-mode');
    }

    if (SETTINGS.PLATFORM.OS.isFirefox) {
      document.body.classList.add('firefox');      
    }

    this.analytics = new GameAnalytics(this);

    this.challenge = new Challenge(this);

    this.audio = new AudioGame(this);

    this.runner = new Runner(this);

    this.screensManager = new ScreensManager(this);

    this.FBGame = new FacebookGame(this, {
      onReady: () => {
        this.runner.init();
        this.audio.startIntro();
        this.analytics.load();
      }
    });

    this.handleFullScreen();

    if (!SETTINGS.ARCADE_MODE && !SETTINGS.FB_ACTIVE) {
      this.handleFocus();
    }
  
    document.getElementById('wrapper').style.opacity = 1;
  
    this.handleArcadeAutoRefresh();
  }
  handleFullScreen () {
    this._fullscreenActive = false;

    this.expandButton = document.getElementById("expand-btn");

    if (SETTINGS.PLATFORM.OS.isiOS) {
      this.expandButton.style.display = 'none';
    }

    this.expandButton.onclick = () => {
      if (document.fullscreenElement || this._fullscreenActive) {
        this._closeFullscreen();
      } else {
        this._openFullScreen();
      }
    };

    document.addEventListener("fullscreenchange", (event) => {
      if (document.fullscreenElement) {
        this.expandButton.classList.add('closed');   
      } else {
        this.expandButton.classList.remove('closed');   
      }
    });
  }
  _openFullScreen () {
    const elem = document.documentElement;

    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) { /* Firefox */
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) { /* IE/Edge */
      elem.msRequestFullscreen();
    }

    this._fullscreenActive = true;
  }
  _closeFullscreen () {

    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.mozCancelFullScreen) { /* Firefox */
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) { /* Chrome, Safari and Opera */
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) { /* IE/Edge */
      document.msExitFullscreen();
    }
    
    this._fullscreenActive = false;
  }
  handleFocus () {
    if (SETTINGS.FB_ACTIVE) return;
    
    window.addEventListener('blur', () => {
      this.runner.pause();
    });
  }
  reload () {
    window.location.href = window.location.href;
  }
  handleArcadeAutoRefresh () {
    if (!SETTINGS.ARCADE_MODE) {
      return false;
    }
    
    this.arcadeReloadTm = null;

    this.arcadeReloader();

    window.addEventListener('keydown', (e) =>{
      this.arcadeReloader();
    });
    
    window.addEventListener('gc.button.press', (e) =>{
      this.arcadeReloader();
    });

    window.addEventListener('gc.analog.start', (e) =>{
      this.arcadeReloader();
    });
    
  }
  arcadeReloader () {
    clearTimeout(this.arcadeReloadTm);

    this.arcadeReloadTm = setTimeout(()=>{
      this.reload();
    }, SETTINGS.ARCADE_AUTO_REFRESH * 1000);
  }
} 