import InApp from 'detect-inapp';
import Platform from 'platform';
const os = Platform.os.toString();
const platformName = Platform.name;

const checkStringBoolean = (item) => item === `true`;

const inApp = new InApp(navigator.userAgent || navigator.vendor || window.opera);

// const URLOptions = {};
// window.location.search.slice(1).split('&').map((option) => {
//   const keyValue = option.split('=');
//   URLOptions[keyValue[0].toUpperCase()] = checkStringBoolean(keyValue[1]) || keyValue[1];
// });

export default {
  VERSION: 0.1,
  ORBIT: false,
  
  // GRAPHICS
  STOP_MOTION: 1,
  PUBLIC: true,
  PAPER_FILTER: true,
  FOG: true,
  LARGE_FOV: inApp.isMobile,
  SHOW_FPS: 0,
  ANISOTROPY: true,
  
  FPS: "auto", // auto for 60 FPS
  REAL_FPS: 30,
  SYNC_FPS_SPEED: false,

  GOD_MODE: 0,
  TRACKS: 3, // Number of lateral track on the ground
  TRACK_WIDTH: 0.5,
  Z_TRACK_END: -11, // -11
  
  BALL_SCORE: 10, //10
  TROPHY_SCORE: 50, //10

  PLATFORM: {
    OS: {
      isiOS : os.toLowerCase().indexOf("ios") > -1,
      isFirefox: platformName.toLowerCase().indexOf('firefox') > -1,
      isAndroid: navigator.platform.toLowerCase().indexOf("android") > -1,
      isChromeiOS: (os.toLowerCase().indexOf("ios") > -1 && platformName.toLowerCase().indexOf("chrome") > -1),
      isEdge: platformName.toLowerCase().indexOf('edge') > -1,
      isIE: platformName.toLowerCase().indexOf('ie') > -1,
      os: os,
      name: platformName
    },
    INAPP: inApp
  },

  //FACEBOOK
  FB_ACTIVE: 0,
  LEADERBOARD_NAME: "world_leaderboard",
  CONTEXT_LEADERBOARD_NAME: "challenge_leaderboard",
  SKIP_INTRO: 0,
  SCREEN: 0,

  //SCORE
  // DB_URL: 'http://localhost:8888/localscores',
  // DB_URL: '/arcadescores',
  DB_URL: '/scores',

  DASH_SEQ: false,

  S_INTRO: 1,
  S_INGAME: 8,
  S_END: 3,

  //GAMEPLAY
  START_SPEED: 0.07, // 0.007
  MAX_SPEED: 0.2, // 0.2
  ACCELERATION: inApp.isMobile ? 0.000005 : 0.00001, // 0.00001
  
  //SECURITY
  SECURITY_SLUG: 'rlwrunner',

  ARCADE_MODE: false,
  ARCADE_AUTO_REFRESH: 300, //sec
  ARCADE_BUTTON: {
    up: "FACE_3", //FACE_2
    down: "FACE_4", //FACE_1
    joystick: {
      up: "DPAD_UP",
      right: "DPAD_RIGHT",
      down: "DPAD_DOWN",
      left: "DPAD_LEFT"
    },
    threshold: 0.1
  },
  ARCADE_MAX_PARTIES: 5,

  ANALYTICS: {
    UA_ID: 'UA-142504664-1',
    DC_ID: 'DC-5923580',
    FB_ID: '287894178255403',
  },
  
  //OVERRIDE OPTIONS
  // ...URLOptions
};