import { TweenLite } from "gsap/TweenMax";
import SETTINGS from "../Settings";
import PerfectScrollbar from 'perfect-scrollbar';

const options = {
  openingDuration: 0.45,
  closingDuration: 0.3
};

class Screen {

  constructor (id, screensManager) {
    this.id = id;
    this.screensManager = screensManager;

    this.element = document.getElementById(id);

    this.init(); 
  }
  init () {

    this._isOpen = false;

    TweenLite.set(this.element, {opacity: 0});

    this.closeButton = this.element.querySelector('.screen__close');

    if (this.closeButton) {
      this.bindEvents();
    }

    if (!SETTINGS.PLATFORM.INAPP.isMobile && !SETTINGS.FB_ACTIVE) {
      this.setupScrollbar();
    }
  }
  get opened () {
    return this._isOpen;
  }
  bindEvents () {
    this.closeButton.addEventListener('click', () =>{
      this.close();
    });
  }
  show () {
    this.element.style.display = 'flex';
  }
  hide () {
    this.element.style.display = 'none';
  }
  open (callback = null) {
    if (this._isOpen) return null;

    this._isOpen = true;

    this.show();

    TweenLite.to(this.element, options.openingDuration, {
      opacity: 1,
      onComplete:() => {
        if (callback) {
          callback();
        }
      }
    });

    return true;

    // this.updatePsScrollBars();
  }
  close (callback = null) {
    if (!this._isOpen) return null;

    this._isOpen = false;

    TweenLite.to(this.element, options.closingDuration, {
      opacity: 0,
      onComplete: () => {
        this.hide();

        if (callback) {
          callback();
        }
      }
    });

    return true;
  }
  setupScrollbar () {
    this.psScrollBars = [];

    this.scrollables = this.element.getElementsByClassName('ps-scrollable');
    
    // if (SETTINGS.INAPP.isMobile && window.innerWidth < 930) {
    //   return false;
    // }

    for (let index = 0; index < this.scrollables.length; index ++ ) {
      this.psScrollBars.push(new PerfectScrollbar(this.scrollables.item(index), {
        suppressScrollX:true,
        maxScrollbarLength: 30,
        minScrollbarLength: 30
      })); 
    }

    if (this.psScrollBars.length > 0) {
      window.addEventListener('resize', ()=>{
        this.updatePsScrollBars();
      });
    }
  }
  updatePsScrollBars () {
    this.psScrollBars.forEach((psScrollBar) => {
      this.updateScrollBar(psScrollBar);
    });
  }
  updateScrollBar (psScrollBar, scrollTop = 0) {    
    psScrollBar.element.scrollTop = 1;

    psScrollBar.update();

    if(psScrollBar.scrollbarYActive){
      psScrollBar.scrollbarYRail.style.display = "block";
    }else{
      psScrollBar.scrollbarYRail.style.display = "none";
    }

    psScrollBar.element.scrollTop = scrollTop;
  }
  onDirectionnal (direction, event) {
  }
  onEnter (event) {
    const enterNextButton = this.element.querySelector('.btn--enter-next');

    if (enterNextButton) {
      enterNextButton.click();      
    }
    
  }
}

export default Screen;